import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useCurriculumQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: curriculum,
    isLoading: isCurriculumLoading,
    isError: curriculumError,
  } = useQuery(
    [QUERY_KEYS.CURRICULUM, slug],
    () => client.getCurriculum(slug),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return { curriculum, isCurriculumLoading, curriculumError };
}
